<template>

  <div class="container">
    <h4 class="mt-4 text-center">Объединение курсов Языкового центра</h4>
    <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>
    <div v-else>
      <div v-if="semesters.length" class="mb-4">
        <div class="mb-2">
                    <span v-if="!semester">
                        <b>Выберите семестер</b>
                    </span>
          <span v-else>
                        <b>Выбран семестер: {{ semester }}</b>
                    </span>
        </div>
        <div class="form-check" v-for="(sem, semIndex) in semesters" :key="semIndex">
          <input class="form-check-input" type="radio" :id="'semester'+sem" :value="sem" v-model="semester"
                 @input="clearCourses">
          <label class="form-check-label" :for="'semester'+sem">
            Семестр {{sem}}
          </label>
        </div>
      </div>

      <div v-if="semester&&courses.length">
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="main_course"><b>Основной курс</b></label>
            <select class="form-select mt-2" id="main_course" v-model="main_course"
                    @input="delete_courses = []">
              <option v-for="(item, index) in semesterCourses"
                      :value="item.course_id"
                      :key="index">
                {{item.selectable_group_name}} {{item.course_name}} ({{item?.lastname}}
                {{item?.firstname}})
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <!--                        <label for="delete_courses">Курс, который надо объединить с основным курсом</label>-->
            <!--                        <select class="form-select" id="delete_courses" multiple v-model="delete_courses">-->
            <!--                            <option v-for="(item, index) in groupCourses"-->
            <!--                                    :value="item.course_id"-->
            <!--                                    :key="index">-->
            <!--                                {{item.selectable_group_name}} {{item.course_name}} ({{item?.lastname}}-->
            <!--                                {{item?.firstname}})-->
            <!--                            </option>-->
            <!--                        </select>-->
            <div>
              <div>
                <b>Курсы, которые надо объединить с основным курсом</b>
              </div>
              <div class="form-check mt-2" v-for="(item, index) in groupCourses" :key="index">
                <input class="form-check-input" type="checkbox"
                       :id="'deleteCourse'+index"
                       :value="item.course_id"
                       v-model="delete_courses">
                <label class="form-check-label" :for="'deleteCourse'+index">
                  {{item.selectable_group_name}} {{item.course_name}} ({{item?.lastname}}
                  {{item?.firstname}})
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="main_course&&delete_courses.length">
          <div>
            <b>Курсы:</b>
          </div>
          <ul class="mb-1 text-danger">
            <li v-for="(item, courseIndex) in checkedCourses" :key="courseIndex">
              {{item?.course_name}} ({{item?.lastname}} {{item?.firstname}})
            </li>
          </ul>
          <div><b>удаляться из Moodle, а студенты данных курсов будут перенесены в курс:</b></div>
          <ul>
            <li>
              <b>{{mainCourse?.course_name}}</b>
              ({{mainCourse?.lastname}} {{mainCourse?.firstname}})
            </li>
          </ul>
        </div>
        <div class="text-center mt-4 mb-4">
          <button class="btn btn-primary" @click="uniteCourses"
                  :disabled="edit||!main_course||!delete_courses.length">
            <span v-if="edit" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            {{edit?'Объединение':'Объединить курсы'}}
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import httpClient from "../../services/http.service";
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: 'UniteCourses',
    data() {
      return {
        semesters: [],
        courses: [],
        loading: true,
        semester: 0,
        main_course: 0,
        delete_courses: [],
        edit: false
      }
    },
    computed: {
      semesterCourses() {
        return this.courses.filter(c => c.semester == this.semester)
      },
      groupCourses() {
        if (this.main_course) {
          const course = this.courses.find(c => c.course_id == this.main_course)
          return this.courses.filter(c => c.selectable_group_name == course.selectable_group_name && c.semester == course.semester).filter(c => c.course_id != this.main_course && c.lecture_pps_id == course.lecture_pps_id)
        }
        return []
      },
      checkedCourses() {
        const courses = []
        this.delete_courses.forEach(i => {
          courses.push(this.courses.find(c => c.course_id == i))
        })
        return courses
      },
      mainCourse() {
        return this.courses.find(c => c.course_id == this.main_course)
      }
    },
    methods: {
      async getLanguageCenterCourses() {
        try {
          const {
            status,
            data
          } = await httpClient.get(`education_program/education-courses/get-language-center-courses?access-token=${getCookie('ACCESS_TOKEN')}`);
          if (status === 200 && data.length) {
            // this.semesters = [...new Set(data.map(i => i.semester))]
            this.semesters = [2, 4, 6, 8]
            this.courses = data
          }
        } catch (e) {
          console.log(e.response);
        }
      },
      async uniteCourses() {
        this.edit = true
        try {
          const form = {
            main_course: this.main_course,
            delete_courses: this.delete_courses
          }
          console.log(form)
          const {
            status,
            data
          } = await httpClient.post(`education_program/education-courses/unite-courses?access-token=${getCookie('ACCESS_TOKEN')}`, form);
          if (status === 200 && data == 'success') {
            this.clearCourses()
            await this.getLanguageCenterCourses()
            this.$message({text: 'Курсы успешно объединены'})
          } else {
            this.$error({text: 'При объединении произошла ошибка'})
          }
        } catch (e) {
          console.log(e.response);
          this.$error({text: 'При объединении произошла ошибка'})
        }
        this.edit = false
      },
      clearCourses() {
        this.main_course = 0
        this.delete_courses = []
      }

    },
    async mounted() {
      await this.getLanguageCenterCourses()
      this.loading = false
    }
  }
</script>

<style scoped>
</style>